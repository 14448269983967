import { Injectable, signal } from '@angular/core';

import { GeneratePhaseType, GeneratePhases } from '../generative-toolbar/generative-toolbar-phases.enum';

/**
 * @class PCGService
 *
 * Представляет службу PCGService, которую можно использовать для переключения состояния isActive.
 *
 * @property {boolean} isGeographicToolPanelActive - Указывает, активна ли служба или нет.
 *
 * @method toggleGenUrbanDesignPanel - Включает/выключает панель с генеративным проектированием.
 */
@Injectable({ providedIn: 'root' })
export class PCGService {
  /**
   * isActive
   *
   * Переменная, представляющая текущее состояние сигнала.
   *
   * @typedef {Object} isGeographicToolPanelActive
   * @property {boolean} value - Текущее значение сигнала.
   *
   * @param {boolean} initialValue - Начальное значение сигнала.
   * @returns {isGeographicToolPanelActive} - Текущее состояние сигнала.
   */
  isGeographicToolPanelActive = signal(false);

  /**
   * Генерирует сигнал для активации фазы генерации.
   * @param {GeneratePhases} phase - Фаза генерации, которую необходимо активировать.
   * @returns {Signal} - Сигнал для активации указанной фазы генерации.
   */
  activeGeneratePhase = signal<GeneratePhaseType>(GeneratePhases.OFF);

  /**
   * Включает/выключает панель с генеративным проектированием.
   *
   * @returns {void}
   */
  toggleGenUrbanDesignPanel(): void {
    this.isGeographicToolPanelActive.update((val) => !val);
  }

  /**
   * Устанавливает активность панели географических инструментов.
   * @param {boolean} isGenUrbanDesignPanelActive - Флаг, указывающий, активна ли панель генерации городского дизайна.
   * @returns {void}
   */
  setGeographicToolPanel(isGenUrbanDesignPanelActive: boolean): void {
    this.isGeographicToolPanelActive.set(isGenUrbanDesignPanelActive);
  }

  /**
   * Устанавливает фазу генерации.
   * @param {GeneratePhaseType} generatePhase - Фаза генерации, которую необходимо установить.
   * @returns {void}
   */
  setGeneratePhase(generatePhase: GeneratePhaseType): void {
    this.activeGeneratePhase.set(generatePhase);
  }
}
